import { EntityField } from '@this/impl/pages/components/entity.field';
import { useEffect, useState } from 'react';
import * as _ from 'lodash';

interface SortConfig {
	key: string;
	direction: 'ascending' | 'descending';
}

const useSortableData = (
	items: any[],
	config: SortConfig | null = null,
	fields?: EntityField[],
) => {
	const [sortConfig, setSortConfig] = useState(config);

	const [sortedItems, setSortedItems] = useState<any[]>([]);

	useEffect(() => {
		const subset = [...items];
		let result = subset;

		if (sortConfig !== null) {
			const typeOf = fields?.find((f) => f.name === sortConfig?.key)?.type ?? 'string';

			// Add to all the items in subset a new field named sortBy with the value of the field to sort in the correct type
			subset.forEach((item) => {
				// @ts-ignore
				if (typeOf === 'number') {
					// @ts-ignore
					item.sortBy = parseInt(item[sortConfig?.key], 10);
				} else if (typeOf === 'date') {
					// @ts-ignore
					item.sortBy = new Date(item[sortConfig?.key]);
				} else {
					// @ts-ignore
					item.sortBy = item[sortConfig?.key];
				}
			});

			if (sortConfig.direction === 'descending') {
				result = _.sortBy(subset, 'sortBy');
			} else {
				result = _.sortBy(subset, 'sortBy').reverse();
			}
		}
		setSortedItems(result);
	}, [fields, items, sortConfig]);

	const requestSort = (key: any) => {
		let direction = 'descending';
		// @ts-ignore
		if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {
			direction = 'ascending';
		}
		// @ts-ignore
		setSortConfig({ key, direction });
	};

	const getClassNamesFor = (key: any) => {
		if (!sortConfig) {
			return 'd-none';
		}
		// eslint-disable-next-line consistent-return
		// @ts-ignore
		return sortConfig.key === key ? sortConfig.direction : 'd-none';
	};

	return { items: sortedItems, requestSort, getClassNamesFor, sortConfig };
};

export default useSortableData;
