import React, { FC, useCallback, useContext, useState } from 'react';
import Document from '@this/impl/api/models/document';
import Structure from '@this/impl/api/models/structure';
import ProgressiveImage from '@this/impl/components/ProgressiveImage';
import Carousel from '@this/template/components/bootstrap/Carousel';
import * as _ from 'lodash';
import LinesEllipsis from 'react-lines-ellipsis';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import moment from 'moment-with-locales-es6';
import Checks from '@this/template/components/bootstrap/forms/Checks';
import api from '@this/impl/api';
import Badge from '@this/template/components/bootstrap/Badge';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle
} from '../../../../template/components/bootstrap/Card';
import useDarkMode from '../../../../template/hooks/useDarkMode';

interface DocumentsProps {
	isFluid?: boolean;
	data: Document[] | Structure[] | undefined;
	emptyMessage?: string;
	cardsPerRow?: number;
	defaultShowPreview?: boolean;
}

const DocumentsCards: FC<DocumentsProps> = ({ isFluid, data, emptyMessage, cardsPerRow, defaultShowPreview }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();
	const categoriesQuery = api.Contents.useCategories();
	// collect all categories from all the datas
	const documentCategories = (data as Document[])?.map((d) => d.categories ?? []);
	// flatten the array
	const categories = _.uniq(_.flatten(documentCategories)).map((c) => {
		return categoriesQuery.all.data?.find((cat) => cat.id === c && cat.visible !== false) ?? { id: '', name: 'Sin categoría' };
	});

	const subset = _.take<Document>(data as Document[], 30);
	const chunks = _.chunk(subset, cardsPerRow ?? 4);
	const [showPreview, setShowPreview] = useState<boolean>(defaultShowPreview ?? false);
	const handleShowPreview = () => setShowPreview(v => !v);

	return (
		<div>
			<Checks
				type='switch'
				id='all'

				label="Vista Previa"
				checked={showPreview}
				onChange={handleShowPreview}
				className='border-bottom border-dark mb-2'
			/>
			{categories.length > 0 && (
				<div>
					{categories.map((category) => (
						<div className='row mt-2'>
							<div className='row'>
								<Badge>{category.name}</Badge>
								<Card stretch shadow='none' className='border rounded'>
									<CardHeader>
										{/* <CardTitle>{category.name}</CardTitle> */}
										<CardBody>
											{(data as Document[])?.filter((d) => d.categories?.includes(category.id ?? '')).map((d) => {
												return (
													<div >
														<Link to={`/documents/${d.externalId ?? d.id}`} aria-label={d.name} className=''>
															<LinesEllipsis
																style={{ fontSize: '14px' }}
																text={d.name}
																maxLine='3'
																ellipsis='...'
																trimRight
																basedOn='letters'
															/>
														</Link>
														<LinesEllipsis
															style={{ fontSize: '12px' }}
															text={d.description}
															maxLine='1'
															ellipsis='...'
															trimRight
															basedOn='letters'
														/>
														{showPreview && (
															<div style={{ backgroundColor: '#e6eef2' }} className='text-center rounded mt-2'>
																<ProgressiveImage width='100%' height={150} src={d.thumbnailLink ?? ''} className='rounded center p-2' ariaLabel={d.thumbnailLink} />
															</div>)}
													</div>
												);
											})}
										</CardBody>
									</CardHeader>
								</Card>
							</div>
						</div>
					))}
				</div>

			)
			}

		</div>
	);


};

DocumentsCards.defaultProps = {
	isFluid: false,
	emptyMessage: '',
};

export default DocumentsCards;
